
import { Component, Prop } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import Multiselect from 'vue-multiselect';
import {
 getComponent, sleep,
} from '@/utils/helpers';
import assetsModule from '@/store/modules/assetsModule';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import accountModule from '@/store/modules/accountModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import metaDataModule from '@/store/modules/metaDataModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import { SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
import { Debounce } from 'vue-debounce-decorator';
import TasqSubmittedSection from '@/components/tasqs/TasqSubmittedSection.vue';
import FormFieldPreview from '@/views/FormFieldPreview.vue';
import WellViewItem from '@/components/tasqs/WellItem.vue';
import TasqWellStatus from '@/components/tasqs/TasqWellStatus.vue';
import isOnline from 'is-online';
import workflowModule from '@/store/modules/workflowModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';

@Component({
  components: {
    TasqSubmittedSection,
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    TasqWellStatus,
    FormFieldPreview,
    Multiselect,
    DateRangePicker,
    WellViewItem,
  },
})
export default class TasqMobileFormSubmission extends mixins(DataLoading) {
  @Prop({ type: Object, required: false }) tasqResponseData?: any;

  stepper: any = 1;

  isOnlineApp = navigator.onLine;

  selectedWell: any = null;

  selectedAssetType = 'Well';

  savingFormResponse = false;

  actionErrors: any = [];

  selectedPad: any = null;

  collapseWellStatus = false;

  selectedPerson = {
    value: workflowModule.user.email,
    text: `${workflowModule.user.firstName} ${workflowModule.user.lastName}`,
  };

  get enabledPads() {
    return assetsModule.enabledPads;
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    return assetsModule.enabledWells.map((well) => ({
      text: well.text,
      value: well.value,
    }));
  }

  onlineMode() {
    this.isOnlineApp = true;
  }

  offlineMode() {
    this.isOnlineApp = false;
  }

  beforeDestroy() {
    // window.removeEventListener('resize', this.onResize);
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('online', this.onlineMode);
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('offline', this.offlineMode);
    window.removeEventListener('resize', this.onResize);
  }

  submittedVisible = false;

  formSubmittionPopup = false;

  loadingActionsDetails = false;

  get actionBuilderActions() {
    return tasqFeedbackModule.actionBuilderActions;
  }

  getAttachedFile: any = null;

  actionBuilderActionPayload: any = [];

  @Debounce(1000)
  async formsSelectionNextStepHandler() {
    this.stepper = 2;
    await this.setupActionDetails();
  }

  windowWidth = window.innerWidth;

  windowHeight = window.innerHeight;

  get isMobile() {
    return this.windowWidth < 768;
  }

  get selectedActionInputTab() {
    return tasqFeedbackModule.selectedActionInputTab;
  }

  editableInputs: any = [];

  selectedFormOption: any = {};

  formIsSelected(option) {
    if (option.id === this.selectedFormOption.id) {
      return true;
    }

    return false;
  }

  get selectedForms() {
    const forms = tasqFeedbackModule.selectedForms;
    return [...new Map(forms.map((item) => [item.id, item])).values()];
  }

  async selectFormOption(option) {
    this.loadingActionsDetails = true;
    this.selectedFormOption = option;
    this.formsSelectionNextStepHandler();
  }

  updateFieldValue(payload) {
    const fieldIndex = this.selectedFormPayload.fields.findIndex(
      (field) => field.id === payload.fieldId,
    );
    if (fieldIndex > -1) {
      const formField = JSON.parse(
        JSON.stringify(this.selectedFormPayload.fields[fieldIndex]),
      );
      formField.localVal = payload.value;
      this.selectedFormPayload.fields[fieldIndex] = formField;
    }
  }

  compare(a, b) {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  }

  optionGroupsAvailable(optionsGroup) {
    if (this.stepper === 1) {
      const { actions } = optionsGroup;

      //   const systems = JSON.parse(JSON.stringify(optionsGroup.systems));
      return actions;
    }
    if (this.stepper === 3) {
      return [];
    }
    return [];
  }

  formDetailsLoading = false;

  refreshForm() {
    this.formDetailsLoading = true;
    this.$nextTick(() => {
      this.formDetailsLoading = false;

      this.$forceUpdate();
    });
  }

  optionsAvailable: any = [];

  fetchActions() {
    const forms: any = JSON.parse(
      JSON.stringify(metaDataModule.formBuilderData),
    );
    const formsMetaData: any = forms.reduce((r, a) => {
      const aData = a;
      const rData = r;
      if (!aData.category) {
        aData.category = 'None';
      }
      rData[aData.category] = rData[aData.category] || [];
      rData[aData.category].push(aData);
      return rData;
    }, Object.create(null));
    this.optionsAvailable = Object.keys(formsMetaData).map((key) => ({
      title: key,
      actions: formsMetaData[key],
    }));
  }

  loadingData = false;

  async created() {
    this.loadingData = true;
    this.isOnlineApp = await isOnline();
    await sleep(200);
    this.loadingActionsDetails = true;

    window.addEventListener('online', this.onlineMode);
    window.addEventListener('offline', this.offlineMode);
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    await metaDataModule.getActionsBuilder({ useExisting: true });

    this.fetchActions();

    const { savingForOffline } = tasqsListModule;

    if (savingForOffline) {
      if (
        this.optionsAvailable
        && this.optionsAvailable[0]
        && this.optionGroupsAvailable(this.optionsAvailable[0])
        && this.optionGroupsAvailable(this.optionsAvailable[0])[0]
      ) {
        this.loadingActionsDetails = false;
        this.loadingData = false;
        const forms: any = metaDataModule.formBuilderData;
        // eslint-disable-next-line prefer-destructuring
        if (forms && forms.length) {
          // eslint-disable-next-line prefer-destructuring
          this.selectedFormOption = forms[0];
        }

        this.stepper = 2;
        await this.setupActionDetails();
        // this.selectFormOption(this.optionGroupsAvailable(this.optionsAvailable[0])[0], true);
        await sleep(1000);
        // this.stepper = 1;
      }
    }

    const promiseList: any = [];
    promiseList.push(assetsModule.getEnabledWells());
    promiseList.push(assetsModule.getEnabledPads());

    Promise.all(promiseList)
      .then(async () => {
        if (this.tasq && this.tasq.wellName) {
          this.selectedWell = {
            text: this.tasq.wellName,
            value: this.tasq.wellName,
          };
        }
        // console.log('resolved');

        this.loadingActionsDetails = false;
        this.loadingData = false;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('Error:');
        // eslint-disable-next-line no-console
        console.log(err);
        this.loadingActionsDetails = false;
        this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, {
          text: 'It is not possible to show some data at this moment, please try later',
          error: true,
        });
        this.loadingData = false;
      });

      if (this.tasq) {
      if (
        this.tasq.formsResponse
        && this.tasq.formsResponse.length
        && this.tasq.isManuallyAdded
      ) {
        tasqFeedbackModule.setSeletedForms(this.tasq.formsResponse);
        tasqFeedbackModule.setStepper(2);
      }

      if (this.tasq.snoozed && !this.tasq.isSnoozedForToday) {
        if (
          new Date().getTime() < new Date(this.tasq.snoozed.WaitStartDate).getTime()
          || new Date().getTime() <= new Date(this.tasq.snoozed.UntilDate).getTime()
        ) {
          this.collapseWellStatus = true;
        }
      }
    }
  }

  onResize() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  get currentWellType(): any {
    if (assetsModule.activeTasq) {
      return 'producing';
    }
    return this.$route.query.type;
  }

  get tasq() {
    if (tasqsListModule.isBatchResponding) {
      if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (tasqsListModule.activeTasq) {
      return tasqsListModule.activeTasq as TasqJob;
    }
    // if (this.currentWellType === 'producing') {
    //   console.log( assetsModule.activeTasq);

    // }
    return assetsModule.activeTasq as TasqJob;
  }

  async setupActionDetails() {
    this.actionBuilderActionPayload = [];
    this.loadingActionsDetails = true;
    await sleep(1000);
    if (this.stepper > 0) {
      this.loadingActionsDetails = true;
      const localFormDetail: any = this.selectedFormOption;
      let fromDetails: any = await metaDataModule.getActionsBuilderRecords({
        actionBuilderID: localFormDetail.id,
      });
      if (fromDetails.constructor === Array) {
         // eslint-disable-next-line prefer-destructuring
        fromDetails = fromDetails[0];
      }

      if (fromDetails && fromDetails.actionPayload) {
        localFormDetail.fields = fromDetails.actionPayload;
      } else {
        localFormDetail.fields = [];
      }

      this.selectedFormPayload = localFormDetail;
      this.loadingActionsDetails = false;
    }
  }

  selectedFormPayload: any = {};

  inputTabButtonText = 'Submit';

  validateFields(fields) {
    const errors: any = [];
    fields.forEach((field) => {
      const errorExist = this.$refs[field.id]![0].validateField();
      if (errorExist) {
        errors.push(errorExist);
      }
    });

    return errors;
  }

  validateAssetDetails() {
    if (this.selectedAssetType === 'Well') {
      if (!this.selectedWell) {
        this.actionErrors.push('selectedWell');
      }
    }

    if (this.selectedAssetType === 'Pad') {
      if (!this.selectedPad) {
        this.actionErrors.push('selectedPad');
      }
    }
  }

  pendingPromises: any = [];

  async submitInput() {
    this.actionErrors = [];
    if (!this.tasq) {
      this.validateAssetDetails();
      if (this.actionErrors.length) {
        return;
      }
    }

    if (
      this.selectedFormPayload
      && this.selectedFormPayload.fields
      && this.selectedFormPayload.fields.length
    ) {
      const errors = this.validateFields(this.selectedFormPayload.fields);

      if (errors && errors.length) {
        return;
      }
    }

    this.savingFormResponse = true;

    let predictionID: any = null;

    if (
      this.tasq
      && this.tasq.wellName
      && this.tasq.predictionType !== 'Producing'
    ) {
      const jobFormResponsePayload = {
        predictionID: this.tasq.id,
        workflowTaskID: this.tasq.workflowTasqId,
        completeForToday: true,
        closeTasq: false,
        formResponse: [this.selectedFormPayload],
        nodeID: this.tasq.wellName,
        validationResponse: tasqFeedbackModule.validationResponse,
      };

      if (navigator.onLine) {
        await metaDataModule.postJobFormsResponse(jobFormResponsePayload);
        predictionID = this.tasq.id;
        const newlyAddedTasq = await tasqsListModule.getRawTasq({
          PredictionID: predictionID,
        });

        tasqsListModule.pushSubmittedTasq(newlyAddedTasq);
      } else {
        const promisePayload: any = {
          // eslint-disable-next-line max-len
          input: jobFormResponsePayload,
          type: 'postJobFormsResponse',
        };
        const updatedTasq = this.tasq;
        tasqsListModule.pushPendingTasqPromise(promisePayload);
        updatedTasq.formsResponse = [this.selectedFormPayload];
        updatedTasq.completedForToday = true;
        tasqsListModule.updateTasqInList({ data: updatedTasq });
        tasqsListModule.pushPendingTasq(updatedTasq);
      }
    } else {
      let well: any = null;
      let { selectedAssetType } = this;
      if (this.selectedAssetType === 'Pad') {
        well = this.selectedPad.text;
      } else {
        well = this.selectedWell.value;
      }

      //  Incase well is producing
      if (this.tasq) {
        well = this.tasq.wellName;
        selectedAssetType = 'Well';
      }

      if (navigator.onLine) {
        const tasqID = await tasqActionsModule.addTasq({
          // eslint-disable-next-line max-len
          assetType: selectedAssetType,
          person: this.selectedPerson,
          well,
          comment: '',
          actions: [this.selectedFormPayload],
          jobType: this.selectedFormPayload.category,
        });
        predictionID = tasqID;
        const newlyAddedTasq = await tasqsListModule.getRawTasq({
          PredictionID: predictionID,
        });
        tasqsListModule.pushSubmittedTasq(newlyAddedTasq);
      } else {
        //  const promises: any = [];

        const promisePayload: any = {
          // eslint-disable-next-line max-len
          input: {
            assetType: selectedAssetType,
            person: this.selectedPerson,
            well,
            comment: '',
            actions: [this.selectedFormPayload],
            jobType: this.selectedFormPayload.category,
          },
          type: 'addTasq',
        };

        //  promises.push(tasqActionsModule.addTasq(promisePayload));
        tasqsListModule.pushPendingTasqPromise(promisePayload);
        const updatedTasq: any = {};
        updatedTasq.level = 'well';
        updatedTasq.formsResponse = [this.selectedFormPayload];
        updatedTasq.wellName = well;
        updatedTasq.completedForToday = true;
        tasqsListModule.pushPendingTasq(updatedTasq);
      }
    }

    this.savingFormResponse = false;

    this.selectedFormOption = [];

    this.confirmBack();
  }

  get submittedTasqForms() {
    // console.log(tasqsListModule.submittedTasqs.map((tasq) => tasq.formResponse)))
    return tasqsListModule.submittedTasqs
      .map((tasq) => tasq.formsResponse.map((form) => ({ wellName: tasq.wellName, form })))
      .flat();
  }

  get pendingTasqForms() {
    return tasqsListModule.pendingTasqs
      .map((tasq) => tasq.formsResponse.map((form) => ({ wellName: tasq.wellName, form })))
      .flat();
  }

  confirmBack() {
    //  tasqsListModule.setActiveTasq('');
    this.stepper = 1;
    this.formSubmittionPopup = false;
    this.selectedFormOption = [];
    this.selectedWell = null;
    this.selectedPad = null;
    //  const currentName: any = this.$route.name;
    //  this.$router.push({ name: currentName });
  }

  stepOneOrTwoBackHandler() {
    this.formSubmittionPopup = true;
  }

  backHandler() {
    if (this.stepper === 1 && this.tasq) {
      tasqsListModule.setActiveTasq('');
      assetsModule.setActiveProducingTasq('');
      tasqsListModule.setActiveTasq('');
      this.$router.push('Tasqs');
    }

    if (this.stepper === 2) {
      this.formSubmittionPopup = true;
    }
  }
}
